/*==========
  Version 1.0
  Squarespace Website Mockups
  Copyright Will Myers 
========== */

.wm-website-mockup-block {
  --scroll-speed: 15s;
  --shadow: var(--shadow-medium);
  --border: 0px solid currentColor;
  --background: transparent;

  --mobile-arrow-thickness: 2px;
  --mobile-arrow-size: 10px;
  --mobile-arrow-color: white;
  --mobile-bkg-color: black;
  --mobile-bkg-opacity: .5;
  --scrollbar-width: 2px;
  --scrollbar-color:#333;
  --scrollbar-track:#ccc;
  
  --hover-scale: 0;
  --hover-speed: .8s;
  --hover-shadow:none;
  
  --aspect-ratio: 1 / 1;
  --tablet-aspect-ratio: 1 / 1;
  --mobile-aspect-ratio: 1 / 1;

  --light:
    0.4px 0.4px 1px hsla(0,0%,0%, 0.25),
    1.5px 1.4px 1.9px -3.9px hsla(0,0%,0%, 0.19);
  --medium:
    0.4px 0.4px 0.5px hsla(0,0%,0%, 0.33),
    9.6px 8.8px 11.8px -2px hsla(0,0%,0%, 0.24),
    25.5px 23.6px 31.5px -3px hsla(0,0%,0%, 0.19);
  --heavy: 
    0.4px 0.4px 0.5px hsla(0,0%,0%, 0.33),
    2.9px 2.7px 3.6px -1px hsla(0,0%,0%, 0.28),
    9.6px 8.8px 11.8px -2px hsla(0,0%,0%, 0.24),
    25.5px 23.6px 31.5px -3px hsla(0,0%,0%, 0.19),
    56px 51.7px 69.2px -3.9px hsla(0,0%,0%, 0.14);
}

.wm-website-mockup{
  transform: scale(1);
  box-shadow: var(--shadow);
  transition: transform var(--hover-speed) ease,
    box-shadow var(--hover-speed) ease;
  border:var(--border-thickness, 0px) solid var(--border-color, currentColor);
}

/*CORE*/
.wm-website-mockup .overflow-wrapper{
  position:relative;
  aspect-ratio: var(--aspect-ratio);
  height: 100%;
  width: 100%;
  
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}
.wm-website-mockup a {
  display:block;
}
.wm-website-mockup .touch-container{
  position:relative;
}

/*Fluid Blocks*/
.fe-block .wm-website-mockup{
  height: 100%;
}
.fe-block .wm-website-mockup .overflow-wrapper{
  aspect-ratio: unset;
}
.fe-block .wm-website-mockup img {
  position:absolute;
  object-fit:cover;
}

.wm-website-mockup img {
  height: auto !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  object-fit: cover;
  transform: translateY(0%);
  transition-timing-function:var(--ease);
  transition-duration:calc(var(--scroll-speed) / 2);
  transition-property:transform;
  will-change: transform;
  pointer-events: none;
}

@media (hover: hover) {
  .wm-website-mockup:hover {
    transform: scale(calc(1 + (var(--hover-scale) / 100) ));
    box-shadow:var(--hover-shadow);
  }
  .wm-website-mockup .overflow-wrapper{
    overflow-y: hidden;
  }
  .wm-website-mockup:hover .overflow-wrapper{
    transform: 0;
  }
  .wm-website-mockup:hover img {
    transform: translateY(calc(-100% + var(--height, 0px)));
    transition-duration:var(--scroll-speed);
  }
}

@media (hover: none) {
  .wm-website-mockup .overflow-wrapper{
    overflow-y: scroll;
    overflow-x: none;
    font-size: 0;
  }
  /* width */
  .wm-website-mockup .overflow-wrapper::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  /* Track */
  .wm-website-mockup .overflow-wrapper::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
  }

  /* Handle */
  .wm-website-mockup .overflow-wrapper::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color);
  }

  /* Handle on hover */
  .wm-website-mockup .overflow-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .wm-website-mockup .touch-background{
    position:relative;
    opacity:1;
    transition:opacity .3s ease;
    z-index:2;
    position:absolute;
    bottom:0;
    height:40px;
    width:100%;
  }
  .wm-website-mockup .touch-background:before {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image:linear-gradient(
      to top,
      var(--mobile-bkg-color) 0%,
      rgba(256,256,256,0) 100%);
    opacity:var(--mobile-bkg-opacity);
  }
  .wm-website-mockup .touch-arrows:after {
    content:'';
    position:absolute;
    bottom:8px;
    left:50%;
    z-index:3;
    width: var(--mobile-arrow-size);
    height: var(--mobile-arrow-size);
    border-left: var(--mobile-arrow-thickness) solid;
    border-bottom: var(--mobile-arrow-thickness) solid;
    border-color:var(--mobile-arrow-color);
    transform:translateX(-50%) rotate(-45deg);
    animation: bounce 3s infinite;
  }
  .wm-website-mockup .touch-container{
    opacity: 1;
    transition: opacity .3s ease;
  }
  .wm-website-mockup .touch-container.scrolled{
    opacity:0;
  }
}

/*Tablet Aspect Ratio*/
@media(max-width:1024px) {
  .wm-website-mockup-block {
    --aspect-ratio: var(--tablet-aspect-ratio) !important;
  }
}

/*Mobile Aspect Ratio*/
@media(max-width:767px) {
  .wm-website-mockup-block {
    --aspect-ratio: var(--mobile-aspect-ratio) !important;
  }
}

/*Hide Squarespace Block*/
body:not(.sqs-edit-mode-active) .wm-website-mockup-block .sqs-block-content{
  display: none;
}

/**
 * Squarespace Backend Editing Modes
 */

/*Unset Hover Effects*/
.sqs-device-view-phone .wm-website-mockup .overflow-wrapper{
  overflow-y: unset;
}
.sqs-device-view-phone .wm-website-mockup{
  transform: unset !important;
}
.sqs-device-view-phone .wm-website-mockup:hover .overflow-wrapper{
  transform: unset !important;
}
.sqs-device-view-phone .wm-website-mockup:hover img {
  transform: unset !important;
}

/*Set Touch Effects*/
.sqs-device-view-phone .wm-website-mockup .overflow-wrapper{
  overflow-y: scroll;
  overflow-x: none;
  font-size: 0;
}

.sqs-device-view-phone .wm-website-mockup .overflow-wrapper::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.sqs-device-view-phone .wm-website-mockup .overflow-wrapper::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.sqs-device-view-phone .wm-website-mockup .overflow-wrapper::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
}

.sqs-device-view-phone .wm-website-mockup .overflow-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sqs-device-view-phone .wm-website-mockup .touch-background{
  position:relative;
  opacity:1;
  transition:opacity .3s ease;
  z-index:2;
  position:absolute;
  bottom:0;
  height:30px;
  width:100%;
}
.sqs-device-view-phone .wm-website-mockup .touch-background:before {
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-image:linear-gradient(
    to top,
    var(--mobile-bkg-color) 0%,
    rgba(256,256,256,0) 100%);
  opacity:var(--mobile-bkg-opacity);
}
.sqs-device-view-phone .wm-website-mockup .touch-arrows:after {
  content:'';
  position:absolute;
  bottom:8px;
  left:50%;
  z-index:3;
  width: var(--mobile-arrow-size);
  height: var(--mobile-arrow-size);
  border-left: var(--mobile-arrow-thickness) solid;
  border-bottom: var(--mobile-arrow-thickness) solid;
  border-color:var(--mobile-arrow-color);
  transform:translateX(-50%) rotate(-45deg);
  animation: bounce 3s infinite;
}
.sqs-device-view-phone .wm-website-mockup .touch-container{
  opacity: 1;
  transition: opacity .3s ease;
}
.sqs-device-view-phone .wm-website-mockup .touch-container.scrolled{
  opacity:0;
}

/*Tablet Aspect Ratio*/
@media(max-width:1274px) {
.sqs-device-view-desktop .wm-website-mockup-block {
    --aspect-ratio: var(--tablet-aspect-ratio);
  }
}

/*Mobile Aspect Ratio*/
.sqs-device-view-phone .wm-website-mockup-block {
  --aspect-ratio: var(--mobile-aspect-ratio);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0)
      rotate(-45deg);
  } 
  40% {
    transform: translateY(-10px) 
      rotate(-45deg);
  } 
  60% {
    transform: translateY(-4px)
      rotate(-45deg);
  }
} 
